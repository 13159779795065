.chat {
  padding: 0 !important;
}

.chat__messages {
  padding: 10px 15px 10px 15px;
  max-height: 484px;
  height: 484px;
}

.chat__new-message {
  margin-bottom: 25px;
  overflow: auto;
}

.chat__date-wrapper {
  position: relative;
  text-align: center;
  margin-top: 10px;
}

.chat__date-wrapper:before {
  content: '';
  border-top: 1px solid #d5d5db;
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 60%;
  margin: 0 auto;
}

.chat__message-date {
  background-color: #fff;
  padding: 0 15px;
  position: relative;
  color: #7e7976;
}

.chat__message {
  display: flex;
  flex-direction: column;
}

.chat__message--outgoing {
  align-items: flex-end;
}

.chat__message--incoming {
  align-items: flex-start;
}

.chat__message-content {
  position: relative;
  max-width: 85%;
  margin-right: 10px;
  margin-left: 10px;
  padding: 15px 20px;
  text-align: left;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.chat__message-content--incoming {
  background-color: #f2faf6;
  word-break: break-all;
}

.chat__message-content--incoming::after {
  position: absolute;
  top: 10px;
  left: -10px;
  color: #f2faf6;
  content: '\25C0';
}

.chat__message-content--outgoing {
  background-color: #f1f1f1;
  word-break: break-all;
}

.chat__message-content--outgoing::after {
  position: absolute;
  top: 10px;
  right: -10px;
  color: #f1f1f1;
  content: '\25B6';
}

.chat__message-time {
  color: #989898;
}

.chat__message-time--outgoing {
  margin-right: 10px;
}

.chat__message-time--incoming {
  margin-left: 10px;
}

.chat__load-more {
  text-align: center;
  padding: 1px 0;
  background-color: #f1f1f1;
  font-weight: 400;
  user-select: none;
  cursor: default;
}

.chat__load-more:hover {
  color: #00a757;
}

.chat__write-message-wrapper {
  background-color: #f1f1f1;
}

.chat__write-message-wrapper {
  padding: 20px 15px;
}

.chat__write-message {
  position: relative;
}

.chat__message-field {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  height: 38px;
  padding-right: 105px;
  resize: none;
}

.chat__send-button {
  width: 60px;
  padding: 8px !important;
  line-height: 20px !important;
  border: 0 !important;
  position: absolute;
  right: 0;
  top: 1px;
  border-radius: 0 !important;
  border-bottom-right-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.chat__msg-loader {
  width: 16px;
  color: #3e3934;
  position: absolute;
  right: 65px;
  top: 10px;
}

.chat__msg-loader--hidden {
  display: none;
}

.chat {
  height: 35rem;
  margin-top: 0.6rem;
}

@media only screen and (min-width: 1200px) {
  .chat {
    height: 37rem;
    margin-top: 0.6rem;
  }
}
