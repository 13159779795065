.custom-select {
  padding: 7px 20px 7px 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAMFBMVEUAAACGjpaGi5iAgICGjJeGjJiHi5aHjJeFi5eHi5aGjJeHi5iGjJeGjJiGjJcAAADjCFh1AAAADnRSTlMAP2MC4553n4ChoqOkVCUQnL8AAAABYktHRACIBR1IAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4AwIBwAg5PVCgQAAAE9JREFUCNdjYMANhAxAJLMig18wiGH6hCHvOVCIue4ZA8e7yQwMlu8aGBj6Xhkwr3sBlOZ4t9kaJAAUenPuBdgAjncQAaDQC6iZvBcY8AIAXzQWtwBAUCsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTYtMTItMDhUMDc6MDA6MzIrMDE6MDC2l9BAAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE2LTEyLTA4VDA3OjAwOjMyKzAxOjAwx8po/AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) 
    calc(100% - 10px) no-repeat;
  background-size: 12px;
  border: 1px solid #d9d6d4;
  box-shadow: none;
  border-radius: 4px;
  font-size: 15px;
  color: #3e3935;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.custom-select-in {
  padding: 7px 35px 7px 10px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAMFBMVEUAAACGjpaGi5iAgICGjJeGjJiHi5aHjJeFi5eHi5aGjJeHi5iGjJeGjJiGjJcAAADjCFh1AAAADnRSTlMAP2MC4553n4ChoqOkVCUQnL8AAAABYktHRACIBR1IAAAACXBIWXMAAA3XAAAN1wFCKJt4AAAAB3RJTUUH4AwIBwAg5PVCgQAAAE9JREFUCNdjYMANhAxAJLMig18wiGH6hCHvOVCIue4ZA8e7yQwMlu8aGBj6Xhkwr3sBlOZ4t9kaJAAUenPuBdgAjncQAaDQC6iZvBcY8AIAXzQWtwBAUCsAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTYtMTItMDhUMDc6MDA6MzIrMDE6MDC2l9BAAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE2LTEyLTA4VDA3OjAwOjMyKzAxOjAwx8po/AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=) 
    calc(100% - 10px) no-repeat;
  background-size: 12px;
  border: 1px solid #d9d6d4;
  box-shadow: none;
  border-radius: 4px;
  font-size: 15px;
  color: #3e3935;
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* For IE10 */
select.custom-select::-ms-expand {
  display: none;
}