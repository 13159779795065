@import url('./widgets/button.css');
@import url('./widgets/card.css');
@import url('./widgets/checkbox.css');
@import url('./widgets/datepicker.css');
@import url('./widgets/money-input.css');
@import url('./widgets/options-dropdown.css');
@import url('./widgets/receipt.css');
@import url('./widgets/select.css');

.hr {
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.edit-photo-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  color: #FFF;
  background-color: #bbbbbb;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #101010;
}

.modal-title {
  font-size: 1.5625rem;
  margin: 0 0 10px 0;
  text-align: center;
}

.payment-method__image-wrapper {
  flex-shrink: 0;
  width: 45px;
  text-align: center;
}