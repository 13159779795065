.em-vertical-margin {
  margin: 1em 0;
}

.flex-block {
  display: flex;
}

.flex-block--space-between {
  justify-content: space-between;
}

.flex-block--vertical-centered {
  align-items: center;
}

.no-margin {
  margin: 0;
}

.standard-link {
  cursor: pointer;
}

.d-flex-inline {
  display: inline-flex;
}

.size-50per {
  width: 50%;
}
